<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ schoolId ? 'Update': 'Add' }} SchoolPay application
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                 SchoolPay application \ Add
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <h4>General Information</h4>
              </v-col>

              <v-col cols="12" md="12">
                <v-chip class="mb-3" v-if="school.user" small>{{school.user}}</v-chip>
                <v-autocomplete
                    v-model="school.user_id"
                    :search-input.sync="userSearch"
                    :items="users"
                    :loading="isLoading"
                    item-text="display_text"
                    item-value="id"
                    label="Users"
                    small-chips
                    deletable-chips
                    clearable
                    outlined
                    dense
                >
                  <template
                      v-slot:label
                  >
                    Enroller <span class="text-danger">*</span>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>Lookup by name or Unique IDs </strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="school.school_name"
                    :error="$v.school.school_name.$error"
                    outlined
                    dense
                    label="School name"
                >
                  <template
                    v-slot:label
                  >
                    School name <span class="text-danger">*</span>
                  </template>  
                </v-text-field>
                <div
                    class="text-danger"
                    v-if="$v.school.school_name.$error"
                >
                  This information is required
                </div>
                <span class="text-danger" v-if="errors.school_name" >*{{errors.school_name[0]}}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="school.email"
                    :error="$v.school.email.$error"
                    outlined
                    dense
                    label="Email"
                >
                  <template
                    v-slot:label
                  >
                    Email <span class="text-danger">*</span>
                  </template>  
                </v-text-field>
                <span
                    class="text-danger"
                    v-if="$v.school.email.$error"
                >
                  This information is required
                </span>
                <span
                    class="text-danger"
                    v-if="!$v.school.email.email"
                >
                  Email should be valid
                </span>
                <span class="text-danger" v-if="errors.email" >*{{errors.email[0]}}</span>
              </v-col>



              <v-col cols="12" md="4">
                <v-text-field
                    v-model="school.phone"
                    :error="$v.school.phone.$error"
                    type="number"
                    outlined
                    label="Phone"
                    dense
                >
                  <template
                    v-slot:label
                  >
                    Phone <span class="text-danger">*</span>
                  </template>  
                </v-text-field>
                <span
                    class="text-danger"
                    v-if="$v.school.phone.$error"
                >
                  This information is required
                </span>
                <span
                    class="text-danger"
                    v-if="!$v.school.phone.numeric"
                >
                  Phone should be a number
                </span>
                <span class="text-danger" v-if="errors.phone" >*{{errors.phone[0]}}</span>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    label="Alt phone"
                    v-model="school.alt_phone"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    v-model="school.website"
                    outlined
                    dense
                    label="Website"
                >
                  <template
                      v-slot:label
                  >
                    Website
                  </template>
                </v-text-field>
                <span
                    class="text-danger"
                    v-if="$v.school.phone.$error"
                >
                  This information is required
                </span>

                <span class="text-danger" v-if="errors.phone" >*{{errors.phone[0]}}</span>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <h4>Contact person information</h4>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                    label="Contact person full name"
                    v-model="school.contact_person_full_name"
                    :error="$v.school.contact_person_email.$error"
                    outlined
                    dense
                >
                  <template
                      v-slot:label
                  >
                    Contact person full name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="school.contact_person_email"
                    :error="$v.school.contact_person_email.$error"
                    outlined
                    dense
                >
                  <template
                    v-slot:label
                  >
                    Contact person email <span class="text-danger">*</span>
                  </template>  
                </v-text-field>
                <span
                    class="text-danger"
                    v-if="$v.school.email.$error"
                >
                  This information is required
                </span>
                <span
                    class="text-danger"
                    v-if="!$v.school.contact_person_email.email"
                >
                  This information is not valid
                </span>
                <span class="text-danger" v-if="errors.email" >*{{errors.email[0]}}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="school.contact_person_phone"
                    :error="$v.school.contact_person_phone.$error"
                    type="number"
                    outlined
                    dense
                > 
                  <template
                    v-slot:label
                  >
                    Contact person phone <span class="text-danger">*</span>
                  </template>  
                </v-text-field>
                <span
                    class="text-danger"
                    v-if="$v.school.contact_person_phone.$error"
                >
                  This information is required
                </span>
                <span
                    class="text-danger"
                    v-if="!$v.school.contact_person_phone.numeric"
                >
                 This information is not numeric
                </span>
                <span class="text-danger" v-if="errors.contact_person" >*{{errors.contact_person[0]}}</span>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <h4>Remarks</h4>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Enroller remarks"
                    v-model="school.user_remarks"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Admin remarks"
                    v-model="school.admin_remarks"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <h4>Address</h4>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="school.address_line_1"
                    :error="$v.school.address_line_1.$error"
                    outlined
                    dense
                >
                  <template
                    v-slot:label
                  >
                    Address line 1 <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span
                    class="text-danger"
                    v-if="$v.school.address_line_1.$error"
                >
                 This information is required
                </span>
                <span class="text-danger" v-if="errors.address_line_1" >*{{errors.address_line_1[0]}}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Address line 2"
                    v-model="school.address_line_2"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Suburb"
                    v-model="school.suburb"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Post code"
                    v-model="school.post_code"
                    type="number"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                    label="Country"
                    v-model="school.country_id"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                    label="State"
                    v-model="school.state_id"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6" v-if="schoolId">
                <v-select
                    label="Application status"
                    v-model="school.application_status"
                    :items="applicationStatus"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-switch
                    v-model="school.is_active"
                    label="Status"
                    color="indigo darken-3"
                ></v-switch>
              </v-col>

            </v-row>
            <hr>
            <v-row>
              <v-col cols="12 text-right">
                 <v-btn
                  class="cancel-btn"
                  @click="back()" 
                  text
                  x-large
                > Cancel
                </v-btn>
                <v-btn
                    :loading="loading"
                    @click="createOrUpdate"
                    class="btn btn-primary text-white mx-3"
                    x-large
                >
                  Save
                </v-btn>

              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { required, email, numeric } from "vuelidate/lib/validators";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import StateService from "@/services/setting/state/StateService";
import SchoolPayApplicationService from "@/services/school-pay/SchoolPayApplicationService";
import CountryService from "@/services/country/CountryService";

const schoolPayApplication = new SchoolPayApplicationService();
const country = new CountryService();
const user = new AccountholderService();
const state = new StateService();


export default {
  name: "CreateAndUpdate",
  validations: {
    school: {
      school_name: { required },
      email: { required, email },
      phone: { required, numeric },
      address_line_1: { required },
      contact_person_email: { required, email },
      contact_person_phone: { required, numeric },
      contact_person_full_name: { required },
      user_id: { required },
      application_status: { required },
      is_active: { required }
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      menu: false,
      schoolId: null,
      errors: [],
      states: [],
      countries: [],
      users: [],
      currentUser: {},
      userSearch: null,
      applicationStatus: [
        { name: 'Pending', value: 'pending'},
        { name: 'Approved', value: 'approved'},
        { name: 'Reject', value: 'rejected'},
        { name: 'Need Further Info', value: 'need_further_info'},
      ],
      school: {
        user_id: '',
        school_name: '',
        email: '',
        website: '',
        phone: '',
        alt_phone: '',
        contact_person_full_name: '',
        contact_person_email: '',
        contact_person_phone: '',
        user_remarks: '',
        admin_remarks: '',
        address_line_1: '',
        address_line_2: '',
        suburb: '',
        state: '',
        post_code: '',
        state_id: '',
        country_id: '',
        application_status: 'pending',
        approved_date: '',
        is_active: true,
      }
    }
  },
  mounted() {
    this.schoolId = this.$route.params.schoolId;
    this.schoolPayDetail();
    this.getAllStates();
    this.getAllCountries();
    this.getCurrentUser();
  },
  watch: {
    userSearch (val) {
        this.isLoading = true;
        user
            .getAllByUserType(val, '')
            .then((response) => {
              response.data.accountholders.map(user => {
                let data=user;
                data.display_text=user.first_name+' '+ user.last_name+' ('+ user.email+')';
                this.users.push(data);
              });
            })
            .catch(err => {

            })
            .finally(() => (this.isLoading = false))
      }
  },
  methods:{
    back() {
      this.$tabs.close();
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    schoolPayDetail() {
      if (this.schoolId != null || this.schoolId != undefined) {
        schoolPayApplication
            .show(this.schoolId)
            .then(response => {
              this.school = response.data.schoolPay;
            });
      }
    },
    getAllStates() {
      state.all().then(response => {
        this.states = response.data.data;
      });
    },
    getAllCountries() {
      country.getAllCountry().then(response => {
        this.countries = response.data.data;
      });
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if (this.schoolId != null || this.schoolId != undefined) this.update();
        else this._create();
      }
    },
    _create: function () {
      this.loading = true;
      schoolPayApplication
          .create(this.school)
          .then(response => {
            this.$snotify.success("SchoolPay application added");
            this.resetForm();
            this.loading = false;
            // this.$tabs.close();
            // this.$tabs.open({
            //   name: 'school-pay-application',
            //   params: { application_status: 'pending'}
            // });

          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update: function () {

      schoolPayApplication
          .update(this.schoolId, this.school)
          .then((response) => {
            this.$snotify.success("SchoolPay application updated");
            this.resetForm();
            this.loading = false;
            this.$tabs.close();
            this.$tabs.open({
              name: 'school-pay-application',
              params: { application_status: 'pending'}
            });

          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.school = {
        user_id: '',
        school_name: '',
        email: '',
        website: '',
        phone: '',
        alt_phone: '',
        contact_person_full_name: '',
        contact_person_email: '',
        contact_person_phone: '',
        user_remarks: '',
        admin_remarks: '',
        address_line_1: '',
        address_line_2: '',
        suburb: '',
        state: '',
        post_code: '',
        state_id: '',
        country_id: '',
        application_status: 'pending',
        approved_date: '',
        is_active: true,
      };
    },
  },
}
</script>
